import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { images } from '../../../../../../library/common/constants/ImageConstants';
import CarouselSlider from '../../../../../UserProfile/Frame/WinLoss/Frame/WinLossModal/Slider';
import '../../../../../UserProfile/Frame/WinLoss/styles.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import axiosInstance from '../../../../../../main/axios';
import { fetchFromStorage } from '../../../../../../utility';
import { URLS } from '../../../../../../library/common/constants/UrlConstants';
import Collapse from 'reactstrap/lib/Collapse';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BiddingAmountCount from './BiddingAmountCount';
// import ActionMessage from '../../../../../../library/common/components/ActionMessage';
import { identifiers } from '../../../../../../library/common/constants/IdentifierConstants';
import { EventEmitter } from '../../../../../../library/common/constants/event';
import PageLoader from '../../../../../../library/common/components/PageLoader';
import { ReactComponent as StartBid } from '../../../../../../resources/images/startBid.svg';
import { ReactComponent as CurrentBid } from '../../../../../../resources/images/currentBid.svg';
import { ReactComponent as BidIncrement } from '../../../../../../resources/images/bidIncrement.svg';
import './styles.scss';

import parse from 'html-react-parser';
import anchorme from 'anchorme';
import LogoutModal from 'modules/EventDetails/Frames/Raffle/LogoutModal';
import DeleteFeatureModal from 'modules/Events/MyEvents/frames/Tickets/frame/DeleteFeatureModal';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { SocketContext } from '../../../../../../main/context/socket';
import { setApiMessage } from '../../../../../../library/common/constants/function';
import { saveToStorage } from 'library/utilities/Storage';

// import { connectSocket } from '../../../../../../main/socket/socket.services';
import { CurrencyFormat } from 'utility';

const EventDetailsAuctionModal = ({
  currency,
  itemFollowing_isToggle,
  isLoggedIn,
  itemFollowing_toggle,
  auctionDetails,
  data,
  categoryList,
  eventDetailsData,
  closeModal,
  singleItem,
  currencyData,
  currencySymbol,
  auctionUpdatedData,
  auctionFeatureData,
  setQuickBid,
  isfromWinningLot,
  makePayment_toggle,
  buyitNowObj,
  userCards,
  isGuestCheckout,
  handleSetBuyItNow,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  var eventID = params?.id;
  const [collapse, setCollapse] = useState(true);
  const [count, setCount] = useState(data?.startBid);
  // const [counter, setCounter] = useState(1);
  const [biddingData, setBiddingData] = useState({});
  const handleCollapse = () => setCollapse(!collapse);
  const [category, setCategory] = useState('');
  const [auctionData, setAuctionData] = useState([]);
  const [bidding, setBidding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isloginToggle, setisloginToggle] = useState(false);
  const [is_toggle, set_Toggle] = useState(false);
  const [bidButtonStatus, setBidButtonStatus] = useState(false);
  const [bidType, setBidType] = useState('');
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  var bidData = data;
  var userDetails = fetchFromStorage(identifiers?.userDetail);
  useEffect(() => {
    setBiddingData(data);
    setCount(data?.currentBid === 0 ? data?.startBid : Number((data?.currentBid + data?.bidIncrement).toFixed(2)));
    var category = categoryList?.filter(item => item.id === data?.categoryId);
    setCategory(category && category[0]?.categoryName);
  }, [data]);
  // console.log('initdata', biddingData);
  // console.log('singleItem', singleItem);

  // useEffect(() => {
  //   console.log('auctionData');
  //   let length = auctionData?.length;
  //   console.log('length',length);
  //   length > 0 ? setBiddingData(data) : setBiddingData(auctionData)
  //   handleMinus();
  // }, [auctionData?.length > 0]);
  // console.log('before', biddingData, auctionData);
  const handleAdd = () => {
    setCount(Number((count + data?.bidIncrement).toFixed(2)));
  };
  const handleMinus = () => {
    if (data?.currentBid === 0) {
      // console.log('if');
      if (count > data?.startBid) {
        setCount(Number((count - data?.bidIncrement).toFixed(2)));
      }
    } else {
      // console.log('else if', count, data?.currentBid + data?.bidIncrement);
      if (count > data?.currentBid + data?.bidIncrement) {
        setCount(Number((count - data?.bidIncrement).toFixed(2)));
      } else {
        // console.log('else else');
        setCount(data?.currentBid + data?.bidIncrement);
      }
    }
  };
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  //   // sendData(message);
  // };
  const resetBiddingData = () => {
    setBiddingData(auctionData);
    // setCount(data?.currentBid);
    // console.log('2222222222222222222', biddingData);
  };
  // console.log('bidCountbidCountbidCount0 111', count);
  EventEmitter.subscribe('updateBiddingData', resetBiddingData);
  // console.log('biddingData?.currentBid', biddingData?.currentBid + biddingData?.bidIncrement);
  // console.log('handlecount', data);

  var eventDetails = fetchFromStorage(identifiers.eventDetails);
  const url = window.location.pathname;
  const spliturl = url.split('/');
  const id = spliturl[spliturl.length - 1];
  var eventId = eventDetails !== null ? eventDetails?.id : id;

  const handleClick = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'auction',
        eventId: eventID,
      },
    });
  };

  const handleModalOpen = () => {
    set_Toggle(!is_toggle);
  };

  const handleBidNow = async () => {
    setBidType('bidNow');
    const token = fetchFromStorage(identifiers.token);
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setisloginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        var queryString = `?token=${token?.token}&sort=PO`;
        setLoading(true);

        const requestData = {
          bidAmount: count,
        };
        try {
          const { status } = await axiosInstance.post(URLS.createBid(bidData?.id), requestData);
          if (status === 201) {
            setBidding(true);
            setApiMessage('success', OKTION_VARIABLE?.OKTION_SUCCESS);
            const { status, data } = await axiosInstance.get(URLS.auctionAll(eventID, queryString));
            if (status === 200) {
              var AUCTION_FEATURE = data?.data?.eventFeatures?.find(item => item?.type === 'auction');
              var auction_data = AUCTION_FEATURE?.auctionOptions?.find(item => item.id === singleItem?.id);
              setAuctionData(auction_data);
              auction_data?.currentBid !== undefined || auction_data?.bidIncrement !== undefined
                ? setCount(Number((auction_data?.currentBid + auction_data?.bidIncrement).toFixed(2)))
                : setCount(Number((singleItem?.currentBid + singleItem?.bidIncrement).toFixed(2)));

              EventEmitter.dispatch('updateBiddingData', auction_data);
              EventEmitter.dispatch('updateBiddingData', auction_data);
              setLoading(false);
            }
          }
        } catch (err) {
          setLoading(false);
          if (err?.response?.data?.msg === 'ERR_BIDDING_ALREADY_EXISTS') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_OUTBID);
          } else if (err?.response?.data?.msg === 'ERR_BIDDING_INTERVAL_MISMATCH') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_INTERVAL_MISMATCH);
          } else if (err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED') {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        set_Toggle(!is_toggle);
      } else {
        setisloginToggle(!isloginToggle);
        setBidButtonStatus(true);
        itemFollowing_toggle();
      }
    }
  };
  const handleBuyItNow = async () => {
    // if ((isLoggedIn && userCards?.length > 0) || userDetails?.userType === 'guest')
    if (isLoggedIn && userDetails?.userType === 'normal') {
      setLoading(true);
      const requestData = {
        eventId: eventID,
      };
      try {
        const { status } = await axiosInstance.put(URLS.buyItNow(bidData?.id), requestData);
        if (status === 200) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_BID_SUCCESSFULLY);
          navigate('/checkout');
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.errCode === 'ERR_CART_ITEM_ALREADY_EXISTS') {
          setApiMessage('error', OKTION_VARIABLE?.OKTION_BIDDING_CARTITEM_EXIST);
        } else {
          setApiMessage('error', err?.response?.data?.msg);
        }
      }
    } else if (isLoggedIn && userDetails?.userType === 'guest') {
      handleGuestBuyitNow();
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    }
    // else if (isLoggedIn && userCards?.length <= 0 && userDetails?.userType !== 'guest') {
    //   set_Toggle(!is_toggle);
    // }
    else {
      setisloginToggle(!isloginToggle);
      itemFollowing_toggle();
    }
  };
  const handleSetMaxBid = async () => {
    setBidType('bidMax');
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setisloginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        setLoading(true);
        const requestData = {
          myMaxBid: count,
        };
        const token = fetchFromStorage(identifiers.token);
        var queryString = `?token=${token?.token}&sort=PO`;
        try {
          const { status } = await axiosInstance.post(URLS.createBid(bidData?.id), requestData);
          if (status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_SET_MAX_BID);
            const { status, data } = await axiosInstance.get(URLS.auctionAll(eventID, queryString));
            if (status === 200) {
              var auction_data = data?.data?.eventFeatures[0]?.auctionOptions?.find(
                item => item.id === biddingData?.id,
              );
              setAuctionData(auction_data);
              EventEmitter.dispatch('updateBiddingData', auction_data);
              EventEmitter.dispatch('updateBiddingData', auction_data);
            }
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          if (
            err?.response?.data?.errCode === 'ERR_BIDDING_ALREADY_EXISTS' ||
            err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED'
          ) {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        set_Toggle(!is_toggle);
      } else {
        setisloginToggle(!isloginToggle);
        setBidButtonStatus(true);
        itemFollowing_toggle();
      }
    }
  };

  const handleGuestBuyitNow = () => {
    updateGuestCardList(buyitNowObj);
    navigate('/guest-checkout', {
      state: {
        eventId: params?.id,
      },
    });
  };

  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  return (
    <>
      {' '}
      {/* <ToastContainer autoClose={3000} /> */}
      <div>
        <Modal
          size="lg"
          className="customModal add-price-modal winLossModal event-details-auctionmodal"
          isOpen={itemFollowing_isToggle}>
          <div className="text-end">
            <img className="cursor-pointer" onClick={itemFollowing_toggle} src={images.close} alt="" />
          </div>
          {/* {message.display && (
            <div className="btn-block">
              <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
            </div>
          )} */}
          <ModalBody>
            <div className="modal-auctionBody">
              {loading && <PageLoader />}
              {/*  <div className="img-section"> */}
              {/* <img
                alt="lot img"
                src={identifiers.imageUrlConstant + '' + data?.images[0]?.name}
                className="lot-image"
              /> */}{' '}
              <CarouselSlider images={data?.images} />
              {/*   </div> */}
              <div className="text-center mt-2 fs-16">
                <h4 className="mt-2 mb-2">{data?.itemName}</h4>
                <p className="mb-2 fs-16">{data?.lotNumber}</p>
                <p className="mb-2 fs-16">
                  {data?.providedBy && data?.providedBy === 'sponsoredBy'
                    ? OKTION_VARIABLE?.OKTION_SPONSOR_BY
                    : data?.providedBy === 'donatedBy'
                      ? OKTION_VARIABLE?.OKTION_DONATION_BY
                      : OKTION_VARIABLE?.OKTION_PROVIDED_BY}{' '}
                  {data?.itemProvidedByName}
                </p>
              </div>
              <div className="d-flex align-items-center flex-md-column">
                {!data?.winner?.isAnonymousContribution && (
                  <div>
                    {data?.winner !== null &&
                      data?.winner?.userProfilePicture !== null &&
                      data?.winner?.userProfilePicture !== '' &&
                      data?.winner?.firstName.toLowerCase() !== 'anonymous contributor' ? (
                      <img className={'userProfilePicture'} src={data?.winner?.userProfilePicture} alt="user" />
                    ) : (
                      <div className={'circle'}></div>
                    )}
                  </div>
                )}
                {data?.winner !== null ? (
                  <p className="winningText fs-16 mt-1">
                    {data?.reserveMet
                      ? OKTION_VARIABLE?.OKTION_WINNING_BIDDER
                      : OKTION_VARIABLE?.OKTION_HIGHEST_BIDDER}{' '}
                    :  {data?.reserveMet
                      ? `${data?.winner?.firstName && data?.winner?.firstName} ${data?.winner?.lastName ? data?.winner?.lastName : ''
                      }`
                      : OKTION_VARIABLE?.OKTION_RESERVE_NOT_MET}
                  </p>
                ) : (
                  <p className="winningText fs-16 mt-1">{`${OKTION_VARIABLE?.OKTION_WINNING_BIDDER} : ${OKTION_VARIABLE?.OKTION_NO_CURRENT_WINNER}`}</p>
                )}{' '}
              </div>
              <div className="d-flex sm-d-flex box mb-3">
                <div className="bid-details">
                  <p className="mb-2">
                    {/* <img src={images.winlossModalImg2} alt="" /> */}
                    <StartBid fill={eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : ''} />
                  </p>
                  <h5 className="mb-1">{OKTION_VARIABLE?.OKTION_STARTING_BID}</h5>
                  <h5
                    className="fw-normal colorYellow text-amount-auction"
                    style={{
                      color: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                    }}>
                    {/* {`${currency}  ${parseFloat(biddingData?.startBid).toFixed(2)}`} */}
                    <CurrencyFormat
                      value={data?.startBid !== null ? data?.startBid : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={`${currency} ${currencySymbol}`}
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      // thousandSpacing="2s"
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </h5>
                </div>
                <div className="bid-details">
                  <p className="mb-2">
                    {/* <img src={images.winlossModalImg1} alt="" /> */}
                    <BidIncrement fill={eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : ''} />
                  </p>
                  <h5 className="mb-1">{OKTION_VARIABLE?.OKTION_INCREMENT}</h5>
                  <h5
                    className="fw-normal colorYellow text-amount-auction"
                    style={{
                      color: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                    }}>
                    {/* {`${currency}  ${parseFloat(biddingData?.bidIncrement).toFixed(2)}`} */}
                    <CurrencyFormat
                      value={data?.bidIncrement !== null ? data?.bidIncrement : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={`${currency} ${currencySymbol}`}
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      // thousandSpacing="2s"
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </h5>
                </div>
                <div className="bid-details">
                  <p className="mb-2">
                    {/* <img src={images.winlossModalImg3} alt="" /> */}
                    <CurrentBid fill={eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : ''} />
                  </p>
                  <h5 className="mb-1">{OKTION_VARIABLE?.OKTION_CURRENT_BID}</h5>
                  <h5
                    className="fw-normal colorYellow text-amount-auction"
                    style={{
                      color: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                    }}>
                    {/* {`${currency} ${parseFloat(biddingData?.currentBid).toFixed(2)}`} */}
                    <CurrencyFormat
                      value={bidData?.currentBid !== null ? Number(bidData?.currentBid).toFixed(2) : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={`${currency} ${currencySymbol}`}
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      // thousandSpacing="2s"
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </h5>
                </div>{' '}
              </div>
              <Row>
                <Col sm="12">
                  <h5 onClick={handleCollapse} className="cursor-pointer">
                    {OKTION_VARIABLE?.OKTION_ABOUT_LOT}{' '}
                    <img className={`ms-3 arrowReverse ${!collapse && 'down'}`} src={images.uppArraow} alt="" />
                  </h5>
                  <Collapse isOpen={collapse}>
                    <p className="mt-3 mb-3">
                      {/* {data?.itemAbout} */}
                      {data?.itemAbout !== null && data?.itemAbout !== undefined ? (
                        <div>
                          {parse(
                            anchorme({
                              input: data?.itemAbout.toString().replace(/\n/g, '<br/>'),
                              options: {
                                attributes: {
                                  target: '_blank',
                                  class: 'detected',
                                },
                              },
                            }),
                          )}{' '}
                        </div>
                      ) : (
                        ''
                      )}
                    </p>
                    {category && (
                      <div className="mt-3 mb-3">
                        <p className="fw-500 fs-16">{OKTION_VARIABLE?.OKTION_LOT_CATEGORIES}</p>
                        <p className="fs-16">{category}</p>
                      </div>
                    )}

                    <div className="mt-3 mb-3 bottomLine">
                      {data?.postageAndHandeling && (
                        <>
                          <p className="fw-500 fs-16">{OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING}</p>
                          <p className="fs-16">
                            {/* {currency} {parseFloat(data?.postageAndHandeling).toFixed(2)} */}
                            <CurrencyFormat
                              value={data?.postageAndHandeling !== null ? data?.postageAndHandeling : '0.00'}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={`${currency} ${currencySymbol}`}
                              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                              // thousandSpacing="2s"
                              decimalScale="2"
                              fixedDecimalScale={true}
                            />
                          </p>{' '}
                        </>
                      )}
                      {data?.postageHandlingNote && (
                        <div className="mt-3">
                          <p className="fw-500 fs-16">{OKTION_VARIABLE?.OKTION_POSTAGE_NOTES}</p>
                          <span>{data?.postageHandlingNote}</span>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </Col>
                {/* {console.log('auctionmodal', data)} */}
                {!isfromWinningLot ? (
                  <BiddingAmountCount
                    data={auctionDetails}
                    handleSetMaxBid={handleSetMaxBid}
                    handleBidNow={handleBidNow}
                    handleBuyItNow={handleBuyItNow}
                    handleAdd={handleAdd}
                    handleMinus={handleMinus}
                    currency={currency}
                    currencySymbol={currencySymbol}
                    count={count}
                    auctionData={auctionData}
                    bidding={bidding}
                    eventDetailsData={eventDetailsData}
                    auctionUpdatedData={auctionUpdatedData}
                    auctionFeatureData={auctionFeatureData}
                    // myRef={myRef}
                    // featureData={featureData}
                    singleItem={singleItem}
                    setQuickBid={false}
                  />
                ) : (
                  <Button
                    style={{ width: '100%' }}
                    disabled={data?.paymentStatus === false}
                    className="app-button blue-button mt-2 h-45"
                    onClick={makePayment_toggle}>
                    {singleItem?.markAsPaid === true
                      ? OKTION_VARIABLE?.OKTION_LOTSOLD
                      : OKTION_VARIABLE?.OKTION_MAKEPAYMENT}
                  </Button>
                )}
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <LogoutModal
        isToggle={isloginToggle}
        toggle={() => setisloginToggle(!isloginToggle)}
        data={data}
        handleClick={handleClick}
        isGuestCheckout={isGuestCheckout}
        isfromWinningLot={isfromWinningLot}
        singleItem={buyitNowObj}
        guestUserStatus={bidButtonStatus}
        bidType={bidType}
        bidCount={count}
      />
      <DeleteFeatureModal
        isToggle={is_toggle}
        toggle={handleModalOpen}
        handleDeleteFeature={() => navigate('/user-profile?user-profile-card-details')}
        FROM_WHERE="contribution"
      />
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(EventDetailsAuctionModal);
